import Hero from '../Components/Hero';
import Carousel from '../Components/CarouselTyped';
import { homePageItemLists } from '../Components/CarouselItemLists';
import Content from '../Components/Content';

const HomePage = (props) => {
  return (
    <div style={{ minHeight: '95vh' }}>
      <Content>
        <Hero title={props.title} subTitle={props.subTitle} text={props.text} />
        <Carousel items={homePageItemLists} />
      </Content>
    </div>
  );
};

export default HomePage;
