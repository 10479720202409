import Content from '../Components/Content';
import Hero from '../Components/Hero';
import Carousel from '../Components/CarouselTyped';
import { gamePageItemLists } from '../Components/CarouselItemLists';

const GamePage = () => {
  return (
    <div style={{ minHeight: '95vh' }}>
      <Hero title={'My Games'} />
      <Content>
        <Carousel items={gamePageItemLists} />
      </Content>
    </div>
  );
};

export default GamePage;
