import Pasta from "../Assets/Images/Pasta.jpg";
import Mobile from "../Assets/Images/Mobile.jpg";
import VideoGames from "../Assets/Images/VideoGames.jpg";

export const homePageItemLists = [
  {
    id: 0,
    title: "Item 1",
    subTitle: "Subtitle",
    imgSrc: Pasta,
    link: "https://google.com",
    selected: false,
  },
  {
    id: 1,
    title: "Item 2",
    subTitle: "Something",
    imgSrc: Mobile,
    link: "https://facebook.com",
    selected: false,
  },
  {
    id: 2,
    title: "Item 3",
    subTitle: "My Games",
    imgSrc: VideoGames,
    link: "/games",
    selected: false,
  },
];

export const gamePageItemLists = [
  {
    id: 0,
    title: "Luna's Terror",
    subTitle: "Subtitle",
    imgSrc: Pasta,
    link: "https://google.com",
    selected: false,
  },
  {
    id: 1,
    title: "ADntro",
    subTitle: "VR RPG inspired by Sword Art Online",
    imgSrc: Mobile,
    link: "https://facebook.com",
    selected: false,
  },
  {
    id: 2,
    title: "Item 3",
    subTitle: "My Games",
    imgSrc: VideoGames,
    link: "/games",
    selected: false,
  },
];
