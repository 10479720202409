import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
  var style = {
    textAlign: 'center',
    padding: '20px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '5em',
    width: '100%',
    marginBottom: '2.5vh',
    marginTop: '0',
  };

  var textStyle = {
    color: 'white',
  };

  return (
    <footer className="mt-5" style={style}>
      <Container fluid={true}>
        <Row className="border-top justify-content-between p-3">
          <Col style={textStyle} md={3} sm={12}>
            Angel Rodriguez
          </Col>
          <Col
            style={textStyle}
            className=" d-flex justify-content-end"
            md={3}
            sm={12}
          >
            Developed by Angel Rodriguez
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
