import Card from "./Card";
import { Container, Row } from "react-bootstrap";
import { useState } from "react";

type CarouselItem = {
  id: number;
  title: string;
  subTitle: string;
  imgSrc: string;
  link: string;
  selected: boolean;
};

interface CarouselItemProps {
  items: CarouselItem[] | CarouselItem;
}

const Carousel = ({ items }: CarouselItemProps) => {
  const [carouselItem, setCarouselItems] = useState<CarouselItem>();

  const handleCardClick = (id: number) => {
    if (items instanceof Array) {
      items[id].selected = items[id].selected ? false : true;

      items.forEach((item) => {
        if (item.id !== id) {
          item.selected = false;
        } else {
          setCarouselItems(item);
          console.log(carouselItem);
        }
      });
    }
  };

  const makeItems = () => {
    if (items instanceof Array) {
      return items.map((item: CarouselItem) => {
        return (
          <Card
            item={item}
            click={() => {
              handleCardClick(item.id);
            }}
            key={item.id}
          />
        );
      });
    }
  };

  return (
    <Container fluid={true}>
      <Row className="justify-content-around">{makeItems()}</Row>
    </Container>
  );
};

export default Carousel;
