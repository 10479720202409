import React from 'react';
import Content from '../Components/Content';
import Hero from '../Components/Hero';
import { Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';

var textStyle = {
    color: "white",
}

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: '',
            emailSent: null,
        }
    }

    sendEmail = (e) => {
        e.preventDefault();

    emailjs.sendForm('service_5w5obft', 'template_yhlqd7r', e.target, 'user_Hqfw10nYXIEqYxIAfIoiI')
      .then((result) => {
          console.log(result.text);
  
            this.setState({
                disabled: false,
                emailSent: true,
            });
      }, (error) => {
        this.setState({
            disabled: false,
            emailSent: false
        });
          console.log(error.text);
      });
      e.target.reset();
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render(){
        return (
            <div style={{minHeight: '95vh'}}>
                <Hero title={this.props.title} />
                <Content>
                    <Form onSubmit={this.sendEmail}>
                    <Form.Group>
                            <Form.Label style={textStyle} htmlFor="full-name">Full Name</Form.Label>
                            <Form.Control 
                                id="full-name" 
                                name="name" 
                                type="text" 
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label style={textStyle} htmlFor="email">E-mail</Form.Label>
                            <Form.Control 
                                id="email" 
                                name="email" 
                                type="text" 
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label style={textStyle} htmlFor="message">Message</Form.Label>
                            <Form.Control 
                                id="message" 
                                name="message" 
                                as="textarea"rows="3" 
                                value={this.state.message}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Button 
                            className="d-inline-block" 
                            type="submit" 
                            disabled={this.state.disabled}
                        >
                            Send
                        </Button>
                        {this.state.emailSent === true && <p className="d-inline success-msg">E-mail Sent</p>}
                        {this.state.emailSent === false && <p className="d-inline error-msg">E-mail Not Sent</p>}
                    </Form>
                </Content>
            </div>
        )
    }
}

export default ContactPage;