import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import Footer from './Components/Footer';
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import GamePage from './Pages/GamePage';

var textStyle = {
  color: 'white',
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Angel Rodriguez',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'About', path: '/about' },
        { title: 'Contact', path: '/contact' },
      ],
      home: {
        title: 'No Zero Days',
        subTitle: 'Collection of Projects',
        subText: 'Checkout my Project below',
      },
      about: {
        title: 'About me',
      },
      contact: {
        title: 'Contact me!',
      },
      games: {
        title: 'My Games',
      },
    };
  }

  render() {
    return (
      <Router>
        <Container className="p-0" fluid={true}>
          <div className="diagonal-split-background body">
            {/* Need bg and expand to show hamburger menu whn screen is small */}
            <Navbar className="border-bottom" bg="transparent" expand="lg">
              <Navbar.Brand style={textStyle}>Angel Rodriguez</Navbar.Brand>

              <Navbar.Toggle
                className="border-8"
                aria-controls="navbar-toggle"
              />
              <Navbar.Collapse id="navbar-toggle">
                <Nav className="ml-auto">
                  <Link style={textStyle} className="nav-link" to="/">
                    Home
                  </Link>
                  <Link style={textStyle} className="nav-link" to="/about">
                    About
                  </Link>
                  <Link style={textStyle} className="nav-link" to="/contact">
                    Contact Me
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Route
              path="/"
              exact
              render={() => (
                <HomePage
                  title={this.state.home.title}
                  subTitle={this.state.home.subTitle}
                  subText={this.state.home.subText}
                />
              )}
            />
            <Route
              path="/about"
              render={() => <AboutPage title={this.state.about.title} />}
            />
            <Route
              path="/contact"
              render={() => <ContactPage title={this.state.contact.title} />}
            />
            <Route
              path="/games"
              render={() => <GamePage title={this.state.games.title} />}
            />
          </div>
          <Footer />
        </Container>
      </Router>
    );
  }
}

export default App;
