import React from 'react';
import Content from '../Components/Content';
import Hero from '../Components/Hero';

var textStyle = {
  color: 'white',
};

const AboutPage = (props) => {
  return (
    <div style={{ minHeight: '95vh' }}>
      <Hero title={props.title} />

      <Content>
        <p style={textStyle}>
          Hello there, my name is Angel Rodriguez and I am developer.
        </p>
        <p style={textStyle}>
          Most of my experience is with C# and Javascript, however, I have also
          used Java, SQL and Python. With my knowledge I have created multiple
          React and React-native applications.
        </p>
        <p style={textStyle}>
          {' '}
          I have used C# to develop MVC application using .Net Core. Aditionally
          I have developed multiple games for mobile and pc platforms. These
          games have been 2D and 3D.
        </p>
        <p style={textStyle}>
          In the process of develkoping games I also becae proficient in Adobe
          softwares like Photshop and Illustrator.
        </p>
      </Content>
    </div>
  );
};

export default AboutPage;
